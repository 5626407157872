.page {
    .content {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .main {
        h2,
        .heading {
            width: 100%;
            margin-bottom: 30px;
            font-family: $font-family-headings;
            text-align: center;
        }

        p {
            color: #6c6c6c;
        }
    }

    main > p {
        font-size: 18px;
    }
}

.home {
    .wrap {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;

        .main {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            .home-body-text {
                margin: 60px auto;

                p:first-of-type {
                    @extend .lead;
                }
            }
        }
    }

    .col-sm-6 {
        .newlife-tiles {
            margin-top: 0;

            .nav-secondary ul > li {
                @include mappy-bp(md) {
                    flex: 0 1 48%;
                }
            }
        }
    }
}

.my-commitment {
    p:first-of-type {
        @extend .lead;
    }
}

.my-commitment .main,
.becoming-a-follower-of-jesus .main {
    h2,
    p {
        text-align: center;
    }
}

.my-commitment .main {
    @extend .col-md-10;
    @extend .col-md-offset-1;
    @extend .col-lg-8;
    @extend .col-lg-offset-2;
}

.becoming-a-follower-of-jesus .wrap {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    .main {
        padding-left: 0;
        padding-right: 0;
    }
}

.becoming-a-follower-of-jesus section {
    padding-top: 30px;
    padding-bottom: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    blockquote {
        font-size: $font-size-h4;
    }
}

.becoming-a-follower-of-jesus #sinner,
.becoming-a-follower-of-jesus #gods-gift,
.becoming-a-follower-of-jesus #be-assured {
    min-height: 500px;
    display: flex;
    align-items: center;
}

.becoming-a-follower-of-jesus #sinner {
    color: $white !important;
    background-image: url("../images/banner-sinner.jpg");
}

.becoming-a-follower-of-jesus #gods-gift {
    color: $white !important;
    background-image: url("../images/banner-gods-gift.jpg");

    .lead {
        color: $white !important;
    }
}

.becoming-a-follower-of-jesus #be-assured {
    color: $white !important;
    background-image: url("../images/banner-be-assured.jpg");

    .lead {
        color: $white !important;
    }
}

.becoming-a-follower-of-jesus .newlife-tiles {
    @extend .col-sm-8;
    @extend .col-sm-offset-2;
}

.page .passage {
    margin-bottom: 30px;

    blockquote {
        margin-bottom: 15px;
        padding: 0;
        font-size: $font-size-h3;
        text-align: center;
        border-left: 0 none;

        &.heading {
            font-family: $font-family-headings;
        }
    }

    cite {
        display: block;
        width: 100%;
        font-style: normal;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.bible-terms {
    .main {
        @include mappy-bp(sm) {
            column-count: 3;
            column-gap: 60px;
            width: 100%;
        }
    }

    .letter-group-heading {
        font-family: $font-family-headings;
    }

    .bible-term {
        padding-bottom: 15px;
        column-fill: balance;
        break-inside: avoid;

        .bible-term-name {
            font-size: $font-size-h5;
            font-weight: 600;
        }

        .bible-term-definition {
            color: #6c6c6c;
            font-size: $font-size-small;
        }
    }
}

.bible-characters {
    .main {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;

        .bible-character-heading {
            flex: 1 1 100%;
            margin: 30px 0;

            h2 {
                @extend .all-caps;
            }
        }

        .bible-character {
            flex: 0 1 25%;
            padding: 30px;
            text-align: center;

            h3 {
                font-family: $font-family-headings;
            }
        }
    }
}

.join,
.edit {
    .main {
        @extend .col-sm-10;
        @extend .col-sm-offset-1;
    }
}

.sign-in,
.password-recovery {
    .main {
        @extend .col-sm-6;
        @extend .col-sm-offset-3;
    }
}

.profile {
    .profile-sidebar {
        @extend .col-sm-4;

        .profile-user-info {
            margin: -130px 0 0 0;
            padding: 0;
            list-style: none;
            text-align: center;

            li {
                margin-bottom: 30px;
                color: #6c6c6c;

                h3 {
                    color: $black;
                }
            }

            .profile-user-avatar {
                img {
                    height: 140px;
                    width: 140px;
                    border-radius: 50%;
                    @include shadow;
                }
            }

            .label {
                display: block;
                margin-bottom: 5px;
                color: $black;
            }
        }
    }

    .profile-body {
        @extend .col-sm-8;

        section {
            @include mappy-bp(sm) {
                margin-bottom: 60px;
            }
        }

        .heading-label {
            @extend .all-caps;
            display: block;
            margin-top: 15px;
            margin-bottom: 30px;
            text-align: left;
        }

        .profile-inspiration-groups {
            .inspiration-groups {
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-wrap: wrap;
                // Compensate for border around flex items
                margin: -15px;
                padding: 0;
                list-style-type: none;

                .inspiration-group {
                    flex: 1 0 100%;
                    position: relative;
                    padding-bottom: 100%;
                    width: auto;
                    height: auto;
                    overflow: hidden;
                    padding: 0;
                    border: 15px solid $white;
                    transition: all 0.3s;

                    @include mappy-bp(sm) {
                        flex: 1 0 33.33%;
                    }

                    &:before {
                        content: "";
                        float: left;
                        padding-top: 100%;
                    }

                    button {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        background-color: $gray-darker;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border: 0 none;
                        filter: saturate(0);
                        transition: all 0.3s !important;

                        &:before {
                            content: "";
                            float: left;
                            padding-top: 100%;
                        }

                        .inspiration-text {
                            position: relative;
                            top: -24px;
                            flex: 1 1 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            align-self: center;
                            min-height: 100%;
                            margin: 0 auto;
                            @extend .all-caps;
                            color: $white;
                            font-size: 80%;
                            transition: all 0.5s;

                            @include mappy-bp(md) {
                                max-width: 60%;
                                font-size: 100%;
                            }

                            .inspiration-count {
                                font-size: 12px;
                                font-weight: 300;
                            }

                            &:before {
                                top: 0;
                                display: block;
                                margin-bottom: 10px;
                                content: "\e080";
                                font-family: "simple-line-icons";
                                font-size: 24px;
                                visibility: hidden;
                                opacity: 0;
                                transition: all 0.5s;
                            }
                        }

                        &.active-inspiration {
                            filter: saturate(100%) contrast(1);

                            .inspiration-text {
                                top: 0;

                                &:before {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }

                        &.inactive-inspiration {
                            border: 0 none;
                            filter: saturate(0%);
                        }

                        &#christianity-101 {
                            background-image: url("../images/inspirations/christianity-101.jpg");
                        }

                        &#faith-101 {
                            background-image: url("../images/inspirations/faith-101.jpg");
                        }

                        &#gods-love-for-me {
                            background-image: url("../images/inspirations/gods-love-for-me.jpg");
                        }

                        &#grace-redemption {
                            background-image: url("../images/inspirations/grace-redemption.jpg");
                        }

                        &#jesus-quotes {
                            background-image: url("../images/inspirations/jesus-quotes.jpg");
                        }

                        &#pain-suffering {
                            background-image: url("../images/inspirations/pain-suffering.jpg");
                        }

                        &#psalms-proverbs {
                            background-image: url("../images/inspirations/psalms-proverbs.jpg");
                        }

                        &#relationships {
                            background-image: url("../images/inspirations/relationships.jpg");
                        }

                        &#work-finances {
                            background-image: url("../images/inspirations/work-finances.jpg");
                        }
                    }

                    // Zoom on hover effect, overflow is clipped
                    &:hover button,
                    &:focus button {
                        filter: saturate(100%);
                    }
                }
            }
        }

        .profile-testimony {
            p:first-of-type {
            }
        }

        .profile-churches {
            .favourite-churches {
                list-style-type: none;
                padding: 0;

                .panel-heading {
                    .simplefavorite-button {
                        position: absolute;
                        right: 50px;
                    }

                    h3 {
                        margin-top: 0;
                    }
                }

                .panel-body {
                    .church-info-list {
                        margin-bottom: 0;

                        @include mappy-bp(sm) {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            flex-wrap: wrap;
                        }

                        .church-info-item {
                            margin-bottom: 15px;

                            @include mappy-bp(sm) {
                                flex: 1 0 50%;
                                padding: 0 15px 0 0;
                            }

                            dt {
                                @extend .label;
                                color: $black;
                            }

                            dd {
                                color: $gray;
                            }
                        }
                    }
                }
            }
        }

        .profile-my-churches {
            ul#my_churches {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 0.5em;
                    border-bottom: 1px solid #eee;

                    &.load-more {
                        padding: 1em 0;
                        border-bottom: none;

                        & > * {
                            flex: 1;
                        }

                        .load-more-range {
                            text-align: center;
                        }

                        .load-more-per-page {
                            display: flex;
                            align-items: baseline;
                            justify-content: flex-end;

                            label {
                                margin-right: 0.5em;
                            }

                            select {
                                max-width: 60px;
                                background: none !important;
                            }
                        }
                    }

                    & > *:first-child {
                        flex: 1;
                    }
                }
            }
        }
    }
}

.request-a-prayer {
    .main {
        @extend .col-sm-8;
        @extend .col-sm-offset-2;

        p:first-of-type {
            @extend .lead;
        }
    }
}

.find-a-church {
    .main {
        p {
            &:first-of-type {
                @extend .lead;
            }
        }
    }

    .wpsl-search {
        padding: 30px 0;
        margin: 0 0 30px;
        background: none;
        border-bottom: 1px solid #eee;

        #wpsl-search-wrap form {
            @include mappy-bp(sm) {
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
            }

            label {
                float: none;
                width: 100% !important;

                &::after {
                    display: block !important;
                    width: 100% !important;
                }
            }

            .wpsl-input,
            .wpsl-select-wrap,
            #wpsl-category {
                display: inline-block !important;
                margin-left: 1px;
                margin-bottom: 15px !important;
                font-weight: 300;

                @include mappy-bp(sm) {
                    margin-bottom: 0 !important;
                }
            }

            .wpsl-input {
                @include mappy-bp(sm) {
                    flex: 1 1 60%;
                    margin-right: 15px !important;
                }
            }

            .wpsl-input input[type="text"] {
                width: 100% !important;
                @extend .form-control;
            }

            .wpsl-input > div {
                float: none !important;
            }

            .wpsl-select-wrap,
            #wpsl-category {
                width: 100% !important;

                @include mappy-bp(sm) {
                    flex: 1 1 30%;
                    margin-right: 15px !important;
                }

                .wpsl-dropdown {
                    @extend .form-control;
                    width: 100% !important;
                    padding: 16px 20px;

                    .wpsl-selected-item {
                        padding: 0 35px 0 0 !important;
                    }
                }
            }

            #wpsl-radius {
                display: inline-block !important;
                width: 100%;
                float: none !important;
            }

            .wpsl-search-btn-wrap {
                margin-right: 0;
                @include mappy-bp(sm) {
                    flex: 1 1 10%;
                }

                input[type="submit"] {
                    @extend .btn;
                    @extend .btn-default;
                    @extend .btn-md;
                    height: 60px;
                    margin-right: 0;
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }
    }

    .church-name {
        margin-bottom: 0 !important;
        color: $black !important;
        font-size: $font-size-base !important;

        a {
            color: $black;
        }
    }

    .church-address {
        margin-bottom: 15px !important;
        font-size: $font-size-small;
        font-weight: 400;
    }

    dl.church-info-item {
        margin-bottom: 15px;

        dd {
            font-size: $font-size-small;
            margin-bottom: 10px;
        }

        dt {
            font-size: $font-size-small;
        }
    }

    .church-favourite-button {
        margin-bottom: 15px;
    }

    #wpsl-stores {
        padding-right: 15px;

        @include mappy-bp(sm) {
            margin-right: 15px;
        }
    }

    #wpsl-result-list ul {
        li {
            padding: 15px 20px !important;
            border-bottom: 1px solid #ddd !important;

            &:last-of-type {
                border-bottom: none;
            }

            &:hover {
                background-color: #eee;
            }
        }
    }

    .wpsl-infobox {
        @extend .panel;
        @extend .panel-default;
        padding: 15px !important;
        @include shadow();
    }

    .wpsl-info-window {
        margin-right: 0;
        font-family: $font-family-base;

        @include mappy-bp(sm) {
            width: 100%;
            max-width: 100% !important;
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .church-name,
        .church-address {
            @include mappy-bp(sm) {
                flex: 1 0 100%;
            }
        }

        .church-info {
            @include mappy-bp(sm) {
                flex: 1 0 100%;
                display: flex;
                align-items: baseline;
                justify-content: flex-start;
                flex-wrap: wrap;
            }

            dl.church-info-item {
                @include mappy-bp(sm) {
                    flex: 1 0 48%;
                }
            }
        }

        .wpsl-info-actions {
            font-weight: 400;

            @include mappy-bp(sm) {
                flex: 1 0 100%;
            }
        }
    }

    .wpsl-direction-after {
        margin: 10px 0 0 0;
        font-size: $font-size-small !important;
        text-align: left !important;
    }

    // Fixes bug in WP Store Locator's map where no back button is visible in Street View
    .gm-iv-back-icon {
        position: absolute;
        top: 10px;
        height: 85%;
        width: 80%;
        background-color: $black !important;
        border-radius: 3px;

        &:before {
            position: absolute;
            top: 14px;
            left: 2px;
            content: "\e257";
            color: $gray-lighter;
            font-family: "Glyphicons Halflings";
            font-size: $font-size-large !important;
        }
    }
}

// Page banners
.sign-up-prompt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    color: $white;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $black;

    h3 {
        margin-bottom: 30px;
        color: $white !important;
        font-family: $font-family-headings;
    }

    .reasons-to-join {
        margin-bottom: 30px;

        li {
            @extend .label;
            display: block;
            color: $white !important;
        }
    }

    .sign-in-up-buttons {
        margin-bottom: 30px;

        li {
            .btn {
                display: block;
                width: 100%;
                margin-bottom: 15px;
            }

            &:last-of-type .btn {
                margin-bottom: 0;
            }
        }
    }
}

.register-your-church {
    .sign-up-prompt-message {
        @extend .col-md-8;
        @extend .col-md-offset-2;
        @extend .lead;
    }

    .sign-up-prompt {
        @extend .col-md-8;
        @extend .col-md-offset-2;
    }

    ul#church-results-list {
        padding: 0;
        list-style: none;

        & > li {
            width: 100%;
            margin-top: 0.5em;

            & > button {
                @extend .btn-md;
                @extend .btn-light-outline;
                display: inline-block;
                width: 100%;
            }
        }
    }
}

#prayer-request-banner {
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    color: $white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $black;

    p,
    .label {
        color: $white;
    }

    .container {
        @include mappy-bp(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .prayer-request-prompt {
            margin-bottom: 30px;

            @include mappy-bp(sm) {
                flex: 1 0 70%;
                margin-bottom: 0;
                padding-right: 60px;
            }

            h3 {
                font-family: $font-family-headings;
            }
        }

        .prayer-request-quotation {
            @include mappy-bp(sm) {
                flex: 1 30%;
                padding-left: 60px;
            }
        }
    }
}

// Bible layout
#bible {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @include mappy-bp(sm) {
        max-height: calc(100vh - 50px);
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    #bible-sidebar {
        @include mappy-bp(sm) {
            max-height: calc(100vh - 51px);
            overflow-x: hidden;
            overflow-y: auto;
            order: 1;
            flex: 1 1 30%;
        }
    }

    #bible-content {
        height: calc(100vh - 50px);

        @include mappy-bp(sm) {
            order: 2;
            flex: 1 1 70%;
        }

        #bible-primary-nav {
            height: 10vh;
        }

        #bible-passages {
            height: calc(80vh - 50px);
            overflow-x: hidden;
            overflow-y: auto;
        }

        #bible-secondary-nav {
            height: 10vh;
        }
    }
}

#bible-copyright {
    @extend .row;
    padding: 30px 20px;
    margin-left: 0;
    margin-right: 0;

    p {
        @extend .col-md-8;
        @extend .col-md-offset-2;
        text-align: center;
        font-weight: 300;
    }
}

// Bible navigations shared styles
.bible-nav-menu-toggle {
    transition: all 0.2s;

    &:after {
        // .icon-arrow-down
        content: "\e604";
        padding: 0 0 0 10px;
        color: $gray-lighter;
        font-family: "simple-line-icons";
        font-size: 14px;
        transition: all 0.2s;
    }

    &:hover,
    &:focus {
        color: $gray-darker;
        outline: 0;

        &:after {
            color: $gray-darker;
        }
    }
}

// .open .bible-nav-menu-toggle,
.open.bible-nav-menu-toggle {
    transition: all 0.2s;
    color: $gray-darker;

    &:after {
        // .icon-arrow-up
        content: "\e607";
        color: $gray-darker;
        transition: all 0.2s;
    }
}

.bible-nav-previous-toggle {
    transition: all 0.2s;

    &:before {
        // .icon-arrow-left
        content: "\e605";
        padding: 0 10px 0 0;
        color: $gray-lighter;
        font-family: "simple-line-icons";
        font-size: 14px;
        transition: all 0.2s;
    }

    &:hover,
    &:focus {
        color: $gray-darker;

        &:before {
            color: $gray-darker;
        }
    }
}

.bible-nav-next-toggle {
    transition: all 0.2s;

    &:after {
        // .icon-arrow-right
        content: "\e606";
        padding: 0 0 0 10px;
        color: $gray-lighter;
        font-family: "simple-line-icons";
        font-size: 14px;
        transition: all 0.2s;
    }

    &:hover,
    &:focus {
        color: $gray-darker;

        &:after {
            color: $gray-darker;
        }
    }
}

#bible-primary-nav,
#bible-secondary-nav,
#bible-chapter-nav,
#bible-chapters-nav,
#bible-books {
    button,
    .btn-bible {
        @extend .btn;
        @extend .btn-light;
        white-space: normal !important;

        &.bible-nav-next-toggle {
            text-align: right;
        }
    }
}

// Bible primary navigation
#bible-primary-nav-wrap {
    position: relative;

    #bible-primary-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding: 20px 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .bible-chapter-nav-previous {
            flex: 0 1 25%;
            text-align: left;
        }

        .bible-chapter-nav-next {
            flex: 0 1 25%;
            text-align: right;
        }

        .bible-nav-heading {
            flex: 1 0 50%;
            text-align: center;

            h2 {
                margin: 0;
                cursor: pointer;
                white-space: normal;
                text-align: center;

                span {
                    color: $gray-darker !important;
                    font-size: 16px !important;
                    font-family: $font-family-sans-serif !important;
                }
            }
        }
    }
}

// Bible books navigation
#bible-books {
    display: none;
    z-index: 1000;
    position: absolute;
    left: 1px;
    width: 100%;
    max-height: calc(100vh - 150px);
    padding: 0;
    background-color: $white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    dl.bible-testament {
        margin: 0;
        background-color: #fff;

        .bible-testament-header {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 10vh;

            h3 {
                margin: 0;
                cursor: pointer;
                border-radius: 0;
            }

            h3:focus {
                color: $gray-darker;
                outline: 0;
            }

            &.open {
                transition: all 0.2s;
                color: $gray-darker;

                h3:after {
                    // .icon-arrow-up
                    content: "\e607";
                    color: $gray-darker;
                    transition: all 0.2s;
                }
            }
        }

        .bible-old-testament-header {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            &.open {
                border-bottom: 0 none;
            }
        }

        .bible-old-testament-header.open + #bible-old-testament-nav {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        .bible-new-testament-header {
            &.open {
                border-top: 0 none;
            }
        }
    }

    .bible-books-list {
        column-count: 2;
        margin: 0;
        padding: 0 30px 30px 30px;
        list-style-type: none;
        // background-color: #FFF;

        @include mappy-bp(sm) {
            column-count: 3;
        }

        li {
            button {
                margin: 2px 0;
                padding: 4px 8px;
                font-size: 14px;
                white-space: normal;
                text-align: left;

                &.current-book {
                    color: $white;
                    background-color: $gray-darker;
                }
            }
        }
    }

    #bible-old-testament-nav,
    #bible-new-testament-nav {
        display: none;
    }
}

// Bible chapters navigation
#bible-chapters-nav {
    display: none;
    z-index: 1000;
    position: absolute;
    left: 1px;
    width: 100%;
    background-color: $white;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .bible-chapters-list {
        display: flex;
        max-height: 60vh;
        overflow-y: auto;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            flex: 0 1 auto;
            padding: 4px;

            button {
                min-width: 50px;
                min-height: 50px;
                border-radius: 50%;
                text-align: center;

                &.current-chapter {
                    color: $white;
                    background-color: $gray-darker;
                }
            }
        }
    }
}

// Bible secondary navigation
#bible-secondary-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    .bible-chapter-nav-previous {
        flex: 0 1 auto;
        text-align: left;
    }

    .bible-chapter-nav-next {
        flex: 0 1 auto;
        text-align: right;
    }

    .bible-copyright {
        flex: 0 1 auto;

        p {
            margin: 0;
            padding: 0 30px;
            text-align: center;
            font-size: $font-size-small;
            font-weight: 300;
        }
    }
}

#bible-content {
    position: relative;

    .spinner {
        z-index: 10000;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s linear;
        transition: opacity 0.2s linear;

        &.show-spinner {
            visibility: visible;
            opacity: 1;
            transition-delay: 1s;
        }
    }

    .spinner > div {
        width: 18px;
        height: 18px;
        background-color: #333;

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .spinner .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
}

// Bible text styles
#bible-passages {
    position: relative;
    padding: 20px 30px;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s linear;

    @include mappy-bp(sm) {
        padding: 45px 60px;
    }

    &.inactive {
        opacity: 0.5;
        transition: all 0.2s;
        cursor: pointer;
    }

    &.loading {
        visibility: hidden;
        opacity: 0;
    }

    // Fades highlight color in and out
    span[class^="verse_"] {
        transition: background-color 0.2s;
    }

    /* begin nlt style classes */

    p {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        color: $black;
        font-weight: 300;
    }

    p.body {
        text-indent: 1em;
    }

    p.body-ch {
    }

    p.body-ch-hd {
    }

    p.body-hd {
    }

    p.body-fl {
    }

    p.body-fl-sp {
        margin-top: 1em;
    }

    p.body-sp {
        margin-top: 1em;
        text-indent: 1em;
    }

    p.book-name {
        font-size: 2em;
    }

    p.chapter-line {
        margin-top: 24pt;
        color: darkred;
        display: none;
    }

    p.ext-body {
        margin-left: 1em;
        text-indent: 1em;
    }

    p.ext-body-ch {
        margin-left: 1em;
    }

    p.ext-body-fl {
        margin-left: 1em;
    }

    p.ext-body-fl-sp {
        margin-top: 1em;
        margin-left: 1em;
    }

    p.ext-body-sp {
        margin-top: 1em;
        margin-left: 1em;
        text-indent: 1em;
    }

    p.ext-hanging {
        margin-left: 1em;
        text-indent: -1em;
    }

    p.ext-hanging-ch {
        margin-left: 1em;
        text-indent: -1em;
    }

    p.ext-hanging-sp {
        margin-top: 1em;
        margin-left: 1em;
        text-indent: -1em;
    }

    p.ext-list {
        margin-left: 2em;
        text-indent: -1em;
    }

    p.ext-list-vn {
        margin-left: 2em;
        text-indent: -1em;
    }

    p.ext-poet1 {
        margin-left: 3em;
        text-indent: -1em;
    }

    p.ext-poet1-sp {
        margin-top: 1em;
        margin-left: 3.25em;
        text-indent: -1.25em;
    }

    p.ext-poet1-vn {
        margin-left: 3em;
        text-indent: -2em;
    }

    p.ext-poet1-vn-sp {
        margin-top: 1em;
        margin-left: 3em;
        text-indent: -2em;
    }

    p.ext-poet2 {
        margin-left: 5em;
        text-indent: -1em;
    }

    p.list {
        margin-left: 1.25em;
    }

    p.list-sp {
        margin-top: 1em;
        margin-left: 1em;
    }

    p.list-vn {
        margin-left: 1em;
        text-indent: -1em;
    }

    p.list-vn-sp {
        margin-top: 1em;
        margin-left: 1em;
        text-indent: -1em;
    }

    p.poet1 {
        margin-left: 2em;
        text-indent: -1em;
    }

    p.poet1-sp {
        margin-top: 1em;
        margin-left: 2.25em;
        text-indent: -1em;
    }

    p.poet1-vn {
        margin-left: 2em;
        text-indent: -2em;
    }

    p.poet1-vn-ch {
        margin-left: 2em;
        text-indent: -2em;
    }

    p.poet1-vn-sp {
        margin-top: 1em;
        margin-left: 2em;
        text-indent: -2em;
    }

    p.poet2 {
        margin-left: 4em;
        text-indent: -1em;
    }

    p.poet2-vn {
        margin-left: 4em;
        text-indent: -2em;
    }

    p.poet3 {
        margin-left: 6em;
        text-indent: -1em;
    }

    p.poet1-vn {
        margin-left: 1em;
        text-indent: -1em;
    }

    p.poet-fr {
        text-align: right;
        font-style: italic;
    }

    p.psa-book {
    }

    p.psa-hebrew {
        margin-top: 1em;
        font-style: italic;
        margin-left: 10em;
    }

    p.psa-title {
        font-style: italic;
        font-size: 0.85em;
    }

    // p.rule {
    // 	border-top: solid black 1px;
    // }

    p.selah {
        text-align: right;
        font-style: italic;
    }

    p.sos-speaker {
        margin-top: 1em;
        font-style: italic;
        font-weight: bold;
    }

    p.sos-speaker-ch {
        font-style: italic;
        font-weight: bold;
    }

    p.subhead {
        margin-top: 1em;
        margin-bottom: 0.5em;
        color: $gray;
        font-style: italic;
        text-align: center;
    }

    p.subhead-ch {
        @extend .all-caps;
        margin-bottom: 0.5em;
        font-weight: bold;
        text-align: center;
    }

    p.text-crit {
        text-align: center;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    p.tn {
        cursor: pointer;
    }

    table.col2a-sp {
        margin-top: 1em;
        width: 100%;
    }

    table.col2b-sp {
        margin-top: 1em;
        width: 100%;
    }

    table.col2b {
        width: 100%;
    }

    table.ext-col2b {
        margin-left: 1.25em;
        width: 100%;
    }

    table.col3 {
        margin-top: 1em;
        width: 100%;
    }

    td.col2a2 {
        text-align: right;
    }

    th {
        text-align: left;
        @extend .all-caps;
        font-weight: bold;
    }

    span.vn {
        color: $gray;
        vertical-align: super;
        padding: 0 2px;
        font-size: 12px;
        line-height: 1 !important;
    }

    span.ital {
        font-style: italic;
    }

    span.psa-title-sc {
        font-size: 80%;
    }

    span.red {
        @extend .red;
    }

    span.red-ital {
        @extend .red;
        font-style: italic;
    }

    span.red-sc {
        @extend .red;
        font-size: 80%;
    }

    span.sc {
        font-size: 80%;
    }

    span.sc-ital {
        font-style: italic;
        font-size: 80%;
    }

    span.subhead-ital {
        font-style: italic;
    }

    // span.subhead-sc {
    // 	font-size: 80%;
    // }

    span.tn-ital {
        font-style: italic;
    }

    span.tn-ref {
        @extend .all-caps;
        font-weight: bold;
    }

    span.tn-ref-bold-sc {
        @extend .all-caps;
        font-weight: bold;
        font-size: 80%;
    }

    span.tn-sc {
        font-size: 80%;
    }

    span.tn-sc-ital {
        font-size: 80%;
        font-style: italic;
    }

    span.tn-vn {
        position: relative;
        bottom: 1ex;
        color: gray;
        vertical-align: 0;
        font-size: 0.75em;
        padding-right: 0.25em;
    }

    span.fract-num {
        position: relative;
        bottom: 0.5ex;
        vertical-align: 0;
        font-size: 0.75em;
    }

    span.fract-den {
        position: relative;
        top: 0.25ex;
        vertical-align: 0;
        font-size: 0.75em;
    }

    span.fract-slash {
    }

    span.tn-fract-num {
        position: relative;
        bottom: 0.5ex;
        vertical-align: 0;
        font-size: 0.75em;
    }

    span.tn-fract-den {
        position: relative;
        top: 0.5ex;
        vertical-align: 0;
        font-size: 0.75em;
    }

    span.tn-fract-slash {
    }

    span.tn-fract-num {
        position: relative;
        bottom: 0.5ex;
        vertical-align: 0;
        font-size: 0.75em;
        font-style: italic;
    }

    span.tn-fract-den {
        position: relative;
        top: 0.5ex;
        vertical-align: 0;
        font-size: 0.75em;
        font-style: italic;
    }

    span.fract-slash {
        font-style: italic;
    }

    /* end nlt style classes */

    p.body,
    p.body-ch,
    p.body-ch-hd,
    p.body-hd,
    p.list-vn-sp,
    p.list,
    p.list-vn,
    p.poet1-vn-ch-hd,
    p.poet1-vn,
    p.poet1-vn-sp,
    p.poet1-sp,
    p.poet1,
    p.poet2,
    p.body-sp,
    p.body-fl-sp,
    p.ext-body-fl-sp,
    p.ext-body {
        cursor: pointer;
        font-weight: 300;
    }

    table {
        margin: 15px auto;

        tr {
            th {
                padding: 5px 10px;
            }

            td {
                font-weight: 300;
                padding: 5px 10px;
            }
        }
    }

    .tn {
        cursor: pointer;
        text-decoration: none;

        &:before {
            // .icon-bubble
            z-index: 9999 !important;
            content: "\e07d";
            margin: 0 2px;
            color: $gray-light;
            font-family: "simple-line-icons";
            font-size: 16px;
            letter-spacing: normal;
        }

        .inline-note {
            display: none;
        }
    }

    .note-on-verse {
        z-index: 9999 !important;
        padding: 0;
        color: $gray-light;
        font-size: 16px;
        background-color: $white;
    }
}

// Red letter
.red {
    color: #c91a2b;
}

.red-off {
    color: $black !important;
}

// Bible sidebar
#bible-sidebar {
    background-color: $gray-super-light;

    dl.bible-sidebar-list {
        margin: 0;
        padding: 0;

        .list-wrapper {
            display: none;
        }

        dt.bible-sidebar-list-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            cursor: pointer;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                @extend .all-caps;
                color: $gray-light;
                transition: all 0.2s;
            }

            &:hover,
            &:focus,
            &.open {
                span {
                    color: $gray-darker !important;
                }
                &:after {
                    color: $gray-darker !important;
                }
            }
        }

        dl.bible-sidebar-book {
            margin: 0;
            padding: 0;

            dt {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px 10px 30px;
                background-color: $gray-lightest;
                cursor: pointer;

                span {
                    @extend .all-caps;
                    font-size: $font-size-small;
                    color: $gray-light;
                    transition: all 0.2s;
                }

                &:hover,
                &:focus,
                &.open {
                    span {
                        color: $gray-darker !important;
                    }
                    &:after {
                        color: $gray-darker !important;
                    }
                }
                &.open {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

#bible-options-list {
    dt.bible-sidebar-list-name {
        height: 10vh;
    }

    dt.bible-sidebar-list-name:after {
        // .icon-options
        content: "\e602" !important;
    }

    dt.bible-sidebar-list-name.open {
        &:after {
            // .icon-options
            content: "\e602" !important;
        }
    }

    dd > ul {
        margin: 0;
        padding: 10px 20px;
        list-style: none;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        li {
            padding: 10px 0;
        }
    }

    .bible-bookmark {
        button#bible-actions-bookmark {
            transition: all 0.2s;

            span:before {
                position: relative;
                top: 3px;
                content: "\e044";
                margin-right: 10px;
                color: $gray-light;
                font-family: "Glyphicons Halflings";
                transition: all 0.2s;
            }

            &.has-bookmark span:before {
                color: $brand-primary;
            }
        }

        button#bible-actions-bookmark-remove {
            display: none;

            &.has-bookmark {
                display: inline-block;
            }
        }
    }

    #red-letter-control {
        margin: 0;

        input {
            margin: 0;
        }
    }

    #red-letter-control + label {
        @extend .all-caps;
        color: #c91a2b;
    }

    #bible-text-size-options {
        @extend .list-inline;

        li {
            display: inline;

            button {
                width: 50px;
                height: 50px;
                padding: 0;
                color: $gray-light;
                line-height: 22px !important;
                background-color: transparent;
                border-radius: 50%;
                transition: all 0.2s;

                &:hover,
                &:focus {
                    background: $gray-lightest;
                    outline: 0;
                }

                &.on {
                    color: $white;
                    background: $gray-darker;
                }

                &#bible-text-small {
                    font-size: 16px !important;
                }

                &#bible-text-medium {
                    font-size: 18px !important;
                }

                &#bible-text-large {
                    font-size: 22px !important;
                }
            }
        }
    }
}

#bible-search-list {


    ul {
        margin: 0;
        padding: 10px 20px;
        list-style: none;
        text-align: center;       

        li {
            padding: 10px 0;
        }
    }

    

    .bible-search {
        button#bible-actions-search {
            transition: all 0.2s;
            width: 100%;
        }

        .search_span_container{
            position: relative;
            display: block;
        }

        input{
            width: 100%;
            display: block;
            padding-left: 30px;
            margin: 0 auto;
            margin-bottom: 24px;          
        }

        i{
            position: absolute;
            top: 7px;
            left: 7px;
        }

    }

    div.totalCounts{
        width: 100%;
        text-align: center;
        display:none;

        button{
            background: transparent;
            color: #222023 !important;

            &:before, &:after{
                color: #222023 !important;
            }
        }

        p{
            flex: 1 1 95%;
        }

        button.bible-nav-previous-toggle{
            display: none;
            &:before{
                content: "";
            }
        }

        &.pageMove .bible-nav-previous-toggle{
            display: inline-block;
            &:before{
                content: "\e605";
            }
        }

        &.pageEnd .bible-nav-next-toggle{
            display: none;
            &:after{
                content: "";
            }
        }

        &.hideBoth button{
            display: none;
            &:after{
                content: "";
            }
            &:before{
                content: "";
            }
        }
    
    }

    .bible-sidebar-list-name.open + div{
        div.totalCounts{
            display: flex;
        }
    }

    ul.bible-sidebar-verses{
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        
        .pages{
            display: none;
            text-align: left;

            a{
                color: #373737;
                text-decoration: underline;
            }
        }

        .page0{
            display: block;
        }
    }
}

#where-to-find-list {
    dd {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        dt.bible-sidebar-book-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px 10px 30px;
            cursor: pointer;
            background-color: $gray-lightest;
            border-color: transparent;

            span {
                @extend .all-caps;
                font-size: $font-size-small;
                color: $gray-light;
                transition: all 0.2s;
            }

            &:hover,
            &.open {
                span {
                    color: $gray-darker !important;
                }
                &:after {
                    color: $gray-darker !important;
                }
            }
            &.open {
                span {
                    text-decoration: underline;
                }
            }
        }

        ul {
            margin: 0;
            padding: 15px 0;
            list-style: none;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            li {
                padding: 5px 30px;

                a:link,
                a:visited {
                    @extend .all-caps;
                    font-size: $font-size-small;
                    color: $gray-light;
                    transition: all 0.2s;
                }

                a:hover,
                a:focus,
                a:active {
                    color: $gray-darker;
                }
            }
        }
    }
}

#bible-highlights-list,
#bible-notes-list {
    dd.bible-sidebar-list-items {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        span.bible-empty-list-message {
            display: inline-block;
            width: 100%;
            padding: 30px 20px;
            color: $gray-light;
            text-align: center;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 20px 20px 30px !important;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                &:first-of-type {
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                }

                span {
                    flex: 1 1 auto;
                    order: 1;

                    .verse-link {
                        margin-right: 2px;
                        padding: 0 5px;
                        color: $gray-darker;
                        background-color: transparent;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        transition: all 0.2s;

                        &:hover,
                        &:focus {
                            border: 1px solid rgba(0, 0, 0, 0.5);
                            outline: 0;
                        }
                    }
                }

                .delete-list-item {
                    flex: 0 0 24px;
                    order: 2;
                    align-self: flex-start;
                    padding: 0;
                    color: rgba(0, 0, 0, 0.25);
                    background-color: transparent;
                    transition: all 0.2s;

                    &:hover,
                    &:focus {
                        color: rgba(0, 0, 0, 0.5);
                        outline: 0;
                    }
                }
            }
        }
    }
}

// Home page Bible overrides
.home #bible {
    position: relative;
}

.page .main footer .entry-share {
    text-align: center;
}

.error404 .main {
    margin-top: 60px;
    margin-bottom: 60px;
}

// Share NewLife
.share-newlife {
    // Responsive video embed
    .entry-content-asset {
        position: relative;
        margin-bottom: 60px;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .banner-ad-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 30px 0;

        figure {
            flex: 0 0 auto;
            margin-right: 30px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.edit-profile {
    li.is-of-faith {
        width: 100%;
        margin: 3em auto;
        text-align: center;

        label {
            text-align: center;
        }
    }
}
