// Breakpoints
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

// Colors
$brand-primary:        #f98c31;
$black:                #080401;
$white:                #fffefc;
$gray-super-light:     #efefef;
$gray-lightest:        #ddd;
$gray-lighter:         #aaa;
$gray-light:           #777;
$gray:                 #444;
$gray-dark:            #373737;
$gray-darker:          #222023;


// Typography
$font-family-sans-serif:    "Lato", Helvetica, Arial, sans-serif;
$font-family-headings:      'Homemade Apple', cursive;
$font-size-base:            16px;

// Borders
$border-radius-base:  0px;

// Buttons
$btn-default-color:              $white;
$btn-default-bg:                 $gray-darker;
$btn-border-radius-base:  $border-radius-base;
$btn-border-radius-large: $border-radius-base;
$btn-border-radius-small: $border-radius-base;

// Forms
$input-border-focus:             $gray-light;
$input-color-placeholder:        $gray-light;

// Labels
$label-color: $gray;
