body[class^='newlife'].sidebar-primary,
body[class*=' newlife'].sidebar-primary {
  .main {
    @extend .pull-right;
    margin-top: 30px;
    margin-bottom: 30px;

    p {
      color: #6c6c6c;

      &:first-of-type {
        @extend .lead;
      }
    }
  }

  .sidebar {
    margin-bottom: 60px;
    @extend .pull-left;
  }

  header {
    h1 {
      font-family: $font-family-headings;
      font-size: $font-size-h3;
      line-height: 150%;
    }
  }
}

.single-article {
  article {
    blockquote {
      margin-bottom: 15px;
      padding: 0;
      border-left: 0 none;
      font-size: $font-size-large !important;
      color: $black !important;
      @extend .lead;

      p,
      span {
        margin: 0 0 1rem 0 !important;
        font-size: $font-size-large !important;
        color: $black !important;
        @extend .lead;
      }

      .vn,
      .tn {
        display: none;
      }

      .subhead,
      .subhead-ch {
        display: none;
      }

      .sc {
        font-size: $font-size-small !important;
      }
    }

    cite {
      @extend .label;
      display: block !important;
      margin-bottom: 30px;
      font-style: normal;
      text-align: left;
    }
  }
}

article.wpsl_stores {
  padding: 2em 0;
  form {
    ul.church-info {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        flex: 0 1 50%;
        padding: 1em;
      }
    }
  }
}
