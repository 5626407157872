// Checkbox
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  height: 36px;
  padding: 0;
  margin: 0 0 0 25px;
  cursor: pointer;
  line-height: 36px;
  color: $gray-light;
  letter-spacing: 0;
  text-transform: none;
  transition: all .3s;
}

input[type="checkbox"] + label:hover,
input[type="checkbox"] + label:focus {
  color: $gray;
  outline: 0;

  &:before {
    border-color: $gray;
  }
}

input[type="checkbox"] + label:before {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: -25px;
  width: 18px;
  height: 18px;
  content: "";
  font-size: 10px;
  background-color: transparent;
  border: 2px solid $gray-lightest;
  transition: all .3s;
}

input[type="checkbox"]:checked + label:before {
  border-color: $gray;
  background-color: $gray;
  transition: all .3s;
}

input[type="checkbox"] + label:hover,
input[type="checkbox"] + label:focus {
  &:before {
    border: 2px solid $gray;
    transition: all .3s;
  }
}

input[type="checkbox"]:checked + label:hover,
input[type="checkbox"]:checked + label:focus {
  &:before {
    background-color: $gray;
    border-color: $gray;
    transition: all .3s;
  }
}

input[type="checkbox"] + label:after {
  position: absolute;
  top: 11px;
  left: -22px;
  width: 18px;
  height: 8px;
  content: "";
  background-color: transparent;
  border: 3px solid $white;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  opacity: 0;
  visibility: hidden;
  transition: all .1s;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
  visibility: visible;
  transition: all .1s;
}

input[type="checkbox"].checkbox-statement + label {
  height: 24px;
  white-space: normal;
  line-height: 24px;
}

input[type="checkbox"].checkbox-statement + label:after {
  top: 3px;
}

input[type="checkbox"].checkbox-statement + label:before {
  top: 3px;
}

input[type="checkbox"].checkbox-statement.checkbox-centered + label {
  text-align: center;
}

ul.checkbox-group {
  margin-left: 0px;

  li {
    margin: 0;
    padding: 0 15px 0 0;ne-height: 24px;
  }
}

// Radios
input[type="radio"] {
  display: none;
  cursor: pointer;
}

input[type="radio"] + label {
  cursor: pointer;
  color: $gray-lighter;
  transition: all .3s;
}

input[type="radio"]:hover + label,
input[type="radio"]:focus + label {
  color: $gray-darker;
  transition: all .3s;
}

input[type="radio"] + label:hover,
input[type="radio"] + label:focus {
  &:before {
    border-color: $gray;
    transition: all .3s;
  }
}

input[type="radio"]:checked + label:hover
input[type="radio"]:checked + label:focus {
  &:before {
    border-color: $gray;
  }
}

input[type="radio"] + label:before {
  display: inline-block;
  content: "";
  width: 18px;
  height: 18px;
  margin: -2px 6px 0 0;
  vertical-align: middle;
  cursor: pointer;
  background-color: $white;
  border: 2px solid $gray-lighter;
  border-radius: 50%;
  transition: all .1s;
}

input[type="radio"]:checked + label:before {
  border-color: $gray;
  border-width: 6px;
  transition: all .1s;
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

// .page form {
//   margin-top: 30px;
//   margin-bottom: 30px;
// }

form {
  margin-left: 0 !important;

  label {
    display: block;
    padding-bottom: 10px;
    text-align: left;
    @extend .label;
  }

  input[type=text],
  input[type=email],
  input[type=password],
  textarea {
    display: block;
    color: $gray-dark;
    font-size: 18px !important;
    border: 1px solid $gray-lightest !important;
    background-color: $white !important;
    box-shadow: none !important;
    @extend .form-control;
    transition: all .3s;

    &:hover {
      border-color: $gray-darker !important;
    }

    &:focus {
      color: $gray-darker;
      box-shadow: none !important;
      border-color: $gray-light !important;
    }
  }

  input[disabled] {
    background-color: $gray-super-light !important;

    &:hover {
      border-color: $gray-lighter !important;
    }
  }

  input[type=submit] {
    @extend .btn;
    @extend .btn-lg;
    @extend .btn-default;
  }

  textarea {
    min-height: 240px;
  }

  .field-note {
    display: block;
    margin-top: 5px;
    font-size: $font-size-small;
    color: #7f7f7f;
  }

  hr {
    width: 100%;
  }
}

.form-group label {
  color: $gray-light;
  font-size: $font-size-small;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.form-control {
  height: 60px;
}

textarea.form-control {
  padding: 15px 20px;
  font-size: $font-size-base;
  resize: none;
}

textarea#commitment_comment {
  height: 162px;
}

select {
  @extend .form-control;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 18px !important;
  appearance: none !important;
  background: $white url('../images/chevron-down.svg') !important;
  background-size: 16px !important;
  background-repeat: no-repeat !important;
  background-position: 98% center !important;
  border: 1px solid $gray-lightest !important;
  box-shadow: none !important;
  cursor: pointer !important;

  &:hover {
    border-color: $gray-darker !important;
  }

  &:focus {
    border-color: $gray-light !important;
  }
}

button[type=submit] {
  margin-top: 30px;
}

.register-your-church {
  form {
    ul {
      padding: 0;

      @include mappy-bp(sm) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    li {
      list-style: none;
      padding: 0;
      @extend .form-group;
      margin-bottom: 30px;

      @include mappy-bp(sm) {
        flex: 0 1 48%;
      }
    }

    .form-heading {
      width: 100%;
      flex: 1 0 100%;
      margin: 30px 0;
      text-align: center;

      h3 {
        font-family: $font-family-headings
      }
    }

    .form-divider,
    .form-checkbox,
    .form-submit {
      width: 100%;
      flex: 1 0 100%;
      margin: 30px 0;
    }

    .form-checkbox {
      ul {
        width: 100%;
        padding: 0;
        margin: 0;

        li {
          width: 100%;
          margin-bottom: 0;

          & + li {
            margin-top: 30px;
          }

          @include mappy-bp(sm) {
            flex: 0 0 80%;
            margin-left: 10%;
          }

          @include mappy-bp(md) {
            flex: 0 0 60%;
            margin-left: 20%;
          }
        }
      }
    }

    .form-submit {
      text-align: center;
    }
  }
}

.join,
.sign-in,
.password-recovery,
.edit-profile {
  .main {
    form {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        @include mappy-bp(sm) {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        & > li {
          margin-bottom: 30px;

          @include mappy-bp(sm) {
            flex: 0 1 48%;
          }

          &.field-heading {
            text-align: center;

            @include mappy-bp(sm) {
              flex: 1 1 100%;
            }

             h4 {
               font-family: $font-family-headings;
               font-size: $font-size-h3;
             }
          }

          &.user-of-faith {
            @include mappy-bp(sm) {
              flex: 1 1 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: wrap;

              label {
                flex: 1 1 100%;
                text-align: center;
              }

              .wppb-radios {
                flex: 1 1 100%;
                display: flex;
                align-items: stretch;
                justify-content: center;
                flex-wrap: nowrap;
                margin-top: 10px;

                li {
                  flex: 0 1 auto;
                  text-align: left !important;
                }
              }
            }
          }

          &.user-testimony {
            @include mappy-bp(sm) {
              flex: 1 1 100%;
            }
          }

          &.wppb-select &.wppb-select-timezone {
            @include mappy-bp(sm) {
              flex: 1 1 100%;
              display: flex;
              align-items: stretch;
              justify-content: center;
              flex-wrap: wrap;

              label,
              select {
                flex: 0 1 51%;
                text-align: center;
              }
            }
          }

          &.user-faith-engagement {
            @include mappy-bp(sm) {
              flex: 1 1 100%;

              label {
                text-align: center;
              }

              ul {
                justify-content: center;
                flex-wrap: nowrap;
                margin-top: 10px;

                li {
                  flex: 1 1 60px !important;
                  display: flex;
                  flex-direction: column;
                  margin-right: 0;

                  input[type=radio] {
                    align-self: center;
                    flex: 1 1 100%;
                    margin-bottom: 5px;
                  }

                  .wppb-rc-value {
                    flex: 1 1 auto;
                    align-self: center;
                    letter-spacing: 0 !important;
                  }

                  &:first-of-type,
                  &:last-of-type {
                    .wppb-rc-value {
                      width: 100px;
                      flex: 1 1 auto;
                      align-self: center;
                    }
                  }
                }
              }
            }

            ul {
              li {
                .wppb-rc-value {
                  flex: 1 1 100%;
                  // visibility: hidden;
                  text-transform: none;
                }

                &:first-of-type,
                &:last-of-type {
                  .wppb-rc-value {
                    visibility: visible;
                  }
                }
              }
            }
          }
        }

        .wppb-radios {
          @extend .list-inline;

          li {
            margin-right: 10px;
            margin-bottom: 0px;

            &:last-of-type {
              margin-right: 0;
            }

            label {
              @extend .label;
              color: #7f7f7f !important;
            }
          }

          input[type=radio] {
            float: left;
            width: auto !important;
            height: auto !important;
            margin-right: 5px !important;
          }

          label {
            margin-right: 15px !important;
            color: #7f7f7f;
            text-transform: none;
            letter-spacing: 0;
          }
        }
      }

      .form-submit {
        text-align: center;

        input[type=submit] {
          @extend .btn;
          @extend .btn-lg;
          @extend .btn-default;
        }
      }
    }
  }
}

.sign-in {
  .login-username,
  .login-password,
  .login-remember {
    margin-bottom: 30px;
  }

  .login-remember {
    label {
      text-align: center !important;
    }

    input[type=checkbox] {
      display: inline-block;
      width: auto;
      height: auto;
    }
  }

  .login-submit {
    text-align: center;
  }
}

.password-recovery {
  form {
    p {
      margin-bottom: 30px;
      text-align: center;
    }

    ul {
      li {
        flex: 1 1 100% !important;

        label {
          text-align: center !important;
        }
      }
    }
  }
}

// Profile Builder overrides
#select_user_to_edit_form {
  display: none;
  visibility: hidden;
}

#wppb_register_pre_form_message {
  @extend .alert;
  @extend .alert-warning;

  img {
    display: none;
  }
}

#wppb_general_top_error_message {
  @extend .alert;
  @extend .alert-danger;

  &:after {
    content: '.';
  }

  img {
    display: none;
  }
}

#wppb_form_success_message {
  @extend .alert;
  @extend .alert-success;
}

.wppb-warning {
  @extend .alert;
  @extend .alert-danger;
  color: red !important;
  text-align: center !important;
}

.wppb-field-error {
  input {
    border-color: red !important;
  }

  label img {
    display: none;
  }

  .wppb-form-error {
    display: block;
    margin-top: 5px;
    font-size: small;

    &:after {
      content: '.';
    }
  }
}

.wppb-required,
.required-field {
  position: relative;
  top: 6px;
  margin-left: 5px;
  color: red;
  font-size: $font-size-large;
  line-height: .1;
}

.wppb-send-credentials-checkbox {
  flex: 1 1 100% !important;
  text-align: center;

  input,
  label {
    display: inline;
    width: auto;
    height: auto;
  }

  input {
    margin-right: 8px;
  }
}

.wppb-avatar {
  .wppb_upload_button {
    @extend .btn;
    @extend .btn-default;
    padding: 20px 36px;
    border: 0 none !important;

    &:hover {
      border: 0 none !important;
    }
  }

  .upload-field-details {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .file-thumb {
      flex: 0 1 60px;
      margin-right: 15px;

      img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        @include shadow;
      }
    }
  }

  p {
    flex: 0 1 auto;

    .file-name,
    .file-type {
      display: none;
    }
  }
}

.my-commitment #commitment_form {
  margin-top: 60px;

  .checkbox-label {
    display: inline-block !important;
  }

  textarea {
    min-height: 167px !important;
    height: 167px !important;
    max-height: 167px !important;
  }
}

// WP Profile Builder  Social Connect
/* Social Connect buttons style */
@font-face {
    font-family: 'wppb_social_connect_font';
    src: url('../fonts/wppb_social_connect_font.eot');
    src: url('../fonts/wppb_social_connect_font.eot#iefix') format('embedded-opentype'),
    url('../fonts/wppb_social_connect_font.woff') format('woff'),
    url('../fonts/wppb_social_connect_font.ttf') format('truetype'),
    url('../fonts/wppb_social_connect_font.svg#wppb_social_connect_font') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="wppb-sc-icon-"]:before, [class*=" wppb-sc-icon-"]:before {
    font-family: "wppb_social_connect_font";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.wppb-sc-icon-twitter:before { content: '\e801'; } /* 'î ' */
.wppb-sc-icon-facebook:before { content: '\e802'; } /* 'î ‚' */
.wppb-sc-icon-google-plus:before { content: '\e803'; } /* 'î ƒ' */
.wppb-sc-icon-spinner:before { content: '\e800'; } /* 'î €' */

.wppb-sc-button,
.wppb-sc-button-wp-default {
    display: inline-block;
    margin: 0 10px 10px 0;
    border-radius: 2px;
}

.wppb-sc-button {
    padding: 4px 11px;
}

.wppb-sc-button-wp-default {
    padding: 8px 11px;
}

.wppb-sc-facebook-login {
    background-color: #3b5998;
}

.wppb-sc-facebook-login:hover {
    background-color: #4c70ba;
}

.wppb-sc-google-login {
    background-color: #c53829;
}

.wppb-sc-google-login:hover {
    background-color: #dc4e41;
}

.wppb-sc-twitter-login {
    background-color: #0084b4;
}

.wppb-sc-twitter-login:hover {
    background-color: #4099ff;
}

.wppb-sc-icon {
    color: #FFFFFF;
    font-size: 20px;
}

.wppb-sc-button-text,
.wppb-sc-button-wp-default-text {
    display: inline-block;
    border-radius: 2px;
    font-size: 14px;
}

.wppb-sc-button-text {
    padding: 4px 10px 4px 4px;
    margin-bottom: 5px;
    min-width: 250px;
}

.wppb-sc-button-wp-default-text {
    padding: 8px 0;
    margin-bottom: 10px;
    width: 100%;
}

.wppb-sc-button-text:link, .wppb-sc-button-text:visited, .wppb-sc-button-text:hover, .wppb-sc-button-text:active,
.wppb-sc-button-wp-default-text:link, .wppb-sc-button-wp-default-text:visited, .wppb-sc-button-wp-default-text:hover, .wppb-sc-button-text:active {
    font-family: "Arial", sans-serif;
    color: #FFFFFF !important;
    text-decoration: none;
    font-weight: bold;
}

.wppb-sc-button-text .wppb-sc-icon,
.wppb-sc-button-wp-default-text .wppb-sc-icon {
    margin: 0 5px;
}

[id^="wppb-login-widget"] .wppb-sc-button-text {
    width: 100%;
}

@media only screen and ( max-width: 650px ) {
    .wppb-sc-button-text {
        width: 50%;
    }
}

@media only screen and ( max-width: 530px ) {
    .wppb-sc-button-text {
        width: 100%;
    }
}

.wppb-sc-heading-before-reg-buttons-wp-default,
.wppb-sc-heading-before-reg-buttons h3 {
    margin: 10px 0;
}

.wppb-sc-buttons-container {
    padding-bottom: 20px;
}

.wppb-sc-disabled-btn {
    background-color: #808080;
    pointer-events: none;
    cursor: default;
}

/* Animate the spinner icon */
@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.wppb-sc-icon-spinner:before {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}


/* ThickBox style */
#TB_ajaxContent {
    text-align: center;
}

#TB_ajaxContent p {
    padding: 10px 0;
    font-size: 20px;
    margin: 0;
}

#wppb_sc_account_connect,
#wppb_sc_new_account {
    border-radius: 5px;
    margin: 5px 10px;
}

#wppb_sc_submit_account_password,
#wppb_sc_submit_account_email,
#wppb_sc_submit_twitter_your_email,
#wppb_sc_submit_facebook_your_email {
    border-bottom-right-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -webkit-border-top-right-radius: 5px;
}

#wppb_sc_account_connect,
#wppb_sc_new_account,
#wppb_sc_submit_account_password,
#wppb_sc_submit_account_email,
#wppb_sc_submit_twitter_your_email,
#wppb_sc_submit_facebook_your_email {
    background-color: #c53829;
    border: none;
    color: white;
    padding: 6px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    height: 35px;
}

#wppb_sc_account_email,
#wppb_sc_account_password,
#wppb_sc_twitter_your_email,
#wppb_sc_facebook_your_email {
    -moz-border-radius-topleft: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #848484;
    outline:0;
    height: 35px;
    width: 75%;
    padding-left: 10px;
    font-size: 16px;
    margin-right: 0;
}

.wppb_sc_form {
    margin-top: 0 !important;
    margin-left: 0 !important;;
    padding: 0 !important;;
    background: 0 !important;;
    -webkit-box-shadow: none !important;;
    box-shadow: none !important;;
}

// WP Profile Builder Social Connect Ovverides
#wppb-login-wrap {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  justify-content: center;

  #loginform {
    order: 2;
    width: 100%;
  }

  #wppb-sc-buttons-container {
    order: 1;
  }

  .wppb-sc-buttons-text-div {
    text-align: center !important;
  }

  .wppb-sc-button-text {
    padding: 15px 50px !important;
  }
}

.wppb-error {
  @extend .alert;
  @extend .alert-danger;
}

.wppb-success {
  @extend .alert;
  @extend .alert-success;
}
