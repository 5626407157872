.banner {
  min-height: 520px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home .banner {
  min-height: 700px;
  padding-bottom: 30px;
}

.register-your-church .banner ,
.sign-in .banner,
.password-recovery .banner,
.profile .banner,
.edit-profile .banner,
.error404 .banner,
.single-wpsl_stores .banner {
  min-height: 240px;
}

.profile .banner {
  min-height: 240px;
  padding-bottom: 30px;

  .page-title {
    display: none;
  }

  .app-download-links,
  .user-theme-selector {
    margin-top: 30px;
    margin-bottom: 60px;

    @include mappy-bp(sm) {
      margin-bottom: 0;
      text-align: right;
    }
  }
}

#main-nav {
  width: 100%;

  &.nav-off-screen {
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
  }

  &.fixed-to-top {
    position: fixed;
    top: 0;
    z-index: 10000;
    opacity: 1 !important;
    visibility: visible !important;
    transition: all .3s;
    @include shadow;

    .logo {
      flex: 0 0 auto;
      height: 50px;
      width: 50px;
      margin-right: 0;
      transform: none;
    }

    .nav-secondary {
      display: none;
      background-color: rgba(0, 0, 0, .75) !important;

      @include mappy-bp(sm) {
        display: block;
      }
    }
  }
}

.nav-primary {
  width: 100%;
  flex: 0 1 auto;
  align-self: flex-start;
  font-size: $font-size-small;
  letter-spacing: 1px;
  background-color: $black;

  .container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include mappy-bp(sm) {
      flex-wrap: nowrap;
    }
  }

  button,
  .btn {
    @extend .btn-navbar;
  }

  .nav-header {
    order: 1;
    flex: 0 1 auto;

    .nav-logo {
      height: 50px;
      margin: 0;
      overflow: visible;

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        padding-left: 5px;
        padding-right: 5px;
        text-decoration: none;
        transition: all .3s;

        &:hover,
        &:focus {
          background-color: $gray-darker;
          outline: none;
        }
      }

      .logo {
        flex: 0 0 auto;
        height: 50px;
        width: 50px;
        margin-right: 25px;
        transform: scale(1.5);
        transform-origin: left;
      }

      .logotype {
        flex: 0 0 auto;
        display: none;

        @include mappy-bp(md) {
          display: inline-block;
          height: 50px;
          width: 100px;
          padding: 0 10px;
        }
      }
    }
  }

  .nav-menu {
    width: 100%;
    order: 5;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    border-top: 0 none !important;
    box-shadow: none !important;

    @include mappy-bp(sm) {
      width: auto;
      display: flex;
      align-items: center;
      order: 2;
      flex: 1 1 auto;
      background-color: transparent;
    }

    .nav {
      margin: 1em 0;

      @include mappy-bp(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        margin: 0;
      }

      li {
        a {
          @extend .btn;
          @extend .btn-navbar;
          white-space: normal;
        }
      }
    }
  }

  .nav-toggle {
    order: 2;
    flex: 0 1 auto;

    .icon {
      font-size: .9em;

      @include mappy-bp(sm) {
        font-size: 1em;
      }
    }

    @include mappy-bp(sm) {
      display: none;
    }
  }

  .nav-user {
    order: 4;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
    transition: all .3s;

    @include mappy-bp(sm) {
      flex: 0 1 auto;
      order: 3;
    }

    button.dropdown-toggle {
      padding: 0;
    }

    .user-info-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 50px;

      span {
        flex: 0 1 auto;

        &.user-greeting {
          order: 1;
          display: none;

          @include mappy-bp(md) {
            display: inline-block;
          }
        }

        &.user-avatar {
          order: 2;
          flex: 0 0 32px;
          order: 2;

          img {
            width: 32px;
            height: 32px;
            margin-left: 10px;
            border-radius: 50%;
          }
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      padding: 10px 0;
      background-color: $gray-darker;
      border: 0 none;

      li > a {
        @extend .all-caps;
        padding-top: 5px;
        padding-bottom: 5px;
        color: $gray-lighter;
        font-size: 14px;

        &:hover,
        &:active,
        &:focus {
          color: $white;
          background-color: transparent;
        }
      }

      li > a:before {
        padding: 0 10px 0 0;
        font-family: 'simple-line-icons';
        font-size: 14px;
      }

      li.user-profile-link > a:before {
        content: "\e005";
      }

      li.user-logout-link > a:before {
        content: "\e065";
      }
    }

    &.open > button {
      color: $white;
    }

    .nav {
      display: flex;
      align-items: center;

      li {
        flex: 0 1 auto;

        a {
          @extend .btn;
          @extend .btn-navbar;
          font-size: .8em;

          @include mappy-bp(sm) {
            font-size: 1em;
          }

          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
          }
        }

        .join-button {
          padding-top: 15px;
          padding-bottom: 15px;
          color: $white;
        }
      }
    }
  }

  .app-language-selector {
    order: 3;
    flex: 0 1 auto;

    button {
      display: flex;
      align-items: center;
      height: 50px;

      svg {
        flex: 0 0 18px;
        width: 18px;
        height: 18px;
        fill: $gray-lightest;
        transition: fill .2s;

        @include mappy-bp(sm) {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
        }
      }

      &:hover {
        svg {
          fill: $white;
          transition: fill .2s;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
      padding: 10px 0;
      background-color: $gray-darker;
      border: 0 none;

      @include mappy-bp(sm) {
        left: auto;
        right: 0;
      }

      li > a {
        color: $gray-lighter;

        &:hover,
        &:active,
        &:focus {
          color: $white;
          background-color: transparent;
        }
      }
    }

    &.open > button > svg {
      fill: $white;
    }
  }
}

.banner-content {
  flex: 1 1 auto;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .daily-inspiration {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;

    @include mappy-bp(sm) {
      margin-top: 0;
      margin-bottom: 0;
    }

    @include mappy-bp(md) {
      width: 80%;
    }
  }
}

.banner-options {
  @include mappy-bp(sm) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .user-theme-selector,
  .app-download-links {
    ul {
      @extend .list-inline;
      padding: 0 !important;

      li {
        padding: 0 5px !important;

        & > * {
          margin: 0 !important;
        }
      }
    }
  }

  .user-theme-selector {
    @include mappy-bp(sm) {
      order: 2;
      flex: 1 1 auto;
      text-align: right;
    }
  }

  .app-download-links {
    @include mappy-bp(sm) {
      order: 1;
      flex: 1 1 auto;
    }
  }
}

.daily-inspiration,
.page-title {
  color: #fff;
  text-align: center;

  h2 {
    font-family: $font-family-headings;
    font-size: $font-size-h4;

    @include mappy-bp(sm) {
      font-size: $font-size-h3;
    }
  }

  blockquote,
  h3 {
    margin-bottom: 0;
    font-size: $font-size-h3;
    border: none;

    @include mappy-bp(sm) {
      margin: 0 auto;
      font-size: $font-size-h2;
    }
  }

  cite {
    font-size: $font-size-large;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.daily-inspiration {
  blockquote {
    p {
      margin: 0;
    }

    .vn,
    .tn,
    .subhead,
    .subhead-sc,
    .psa-book {
      display: none;
    }

    .red {
      color: $white;
    }
  }
}

.user-theme-selector,
.app-download-links {
  text-align: center;

  @include mappy-bp(sm) {
    text-align: left;
  }

  .label {
    padding: 0;
    color: #fff;
  }

  .list-inline {
    padding: 0;
    margin-top: 15px;
    margin-left: 0;

    @include mappy-bp(sm) {
      margin-left: -5px;
      margin-bottom: 0;
    }

    li {
      padding-left: 2px;
      padding-right: 2px;
      margin-bottom: 10px;

      @include mappy-bp(sm) {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 0;
      }

      .btn {
        padding: 4px 8px;

        @include mappy-bp(sm) {
          padding: $padding-base-vertical $padding-base-horizontal;
        }
      }
    }
  }
}

.page-title {
  width: 100%;
  align-self: flex-end;
  margin-bottom: 50px;
}

.nav-secondary,
.nav-tertiary {
  font-size: $font-size-small;
  text-transform: uppercase;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 0;

    li {
      padding: 4px 8px;

      &.active > a {
        text-decoration: underline !important;
      }
    }
  }
}

.nav-secondary {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: rgba(0, 0, 0, .5);

  ul {
    li > a {
      @extend .btn-navbar;
    }
  }
}

.nav-tertiary {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: rgba(255, 255, 255, .8);

  ul {
    li > a {
      color: #7f7f7f;
      text-decoration: none;

      &:hover,
      &:active {
        color: #000;
      }
    }

    .current_page_ancestor > a,
    .current_page_item > a{
      color: #000;
      text-decoration: none;
    }
  }
}


.main-nav-active-item,
.nav-primary ul > li.active > a {
  color: $white !important;
  text-decoration: underline !important;
}

.content-nav-active-item {
  color: $black !important;
  text-decoration: underline !important;
}


// Active menu items
// Primary level items
body.bible {
  .menu-read-the-bible a {
    @extend .main-nav-active-item;
  }
}

body.bible-terms,
body.bible-characters,
body.becoming-a-follower-of-jesus,
body.my-commitment,
body.join-the-family {
  .menu-explore-faith a,
  .menu-explorer-la-foi a,
  .menu-explore-la-fe a {
    @extend .main-nav-active-item;
  }
}

body.bible-terms,
body.bible-characters {
  .menu-bible-answers a,
  .menu-reponses-de-la-bible a,
  .menu-respuestas-biblicas a {
    @extend .main-nav-active-item;
  }
}

body.becoming-a-follower-of-jesus,
body.my-commitment,
body.join-the-family {
  .menu-ready-to-change a,
  .menu-pret-a-changer a,
  .menu-dispuesto-a-cambiar a {
    @extend .main-nav-active-item;
  }
}

body.find-a-church {
  .menu-find-a-church a {
    @extend .main-nav-active-item;
  }
}

body.request-a-prayer {
  .menu-request-a-prayer a {
    @extend .main-nav-active-item;
  }
}

// Dropdown menus
.dropdown-menu {
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: all .2s;
  @include shadow;
}

.open > .dropdown-menu {
  opacity: 1;
  visibility: visible;
}

.dropdown-menu > .divider {
  background-color: $gray;
}
