.webui-popover {
  width: 100%;
  padding: 0;
  border: 0 none;
  border-radius: 0px;
  @include shadow;

  @include mappy-bp(sm) {
    width: 500px;
  }

  .webui-arrow {
    display: none;
  }

  .webui-popover-title {
    display: block !important;
    width: 100% !important;
    padding: 15px 25px !important;
    color: $white;
    text-align: left !important;
    border: 0 none;
    border-radius: 0px;
    background-color: $black;
    @extend .label;
  }

  .webui-popover-content {
    padding: 15px 25px 20px 25px !important;
  }

  .close {
    &:after {
      content: "\e082";
      font-family: "simple-line-icons";
    }
  }

  // Tool tip arrow
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: solid transparent;
  }

  &:after {
    margin-left: -9px;
    border-color: rgba(255, 255, 255, 0);
    border-top-color: $white;
    border-width: 9px;
  }

  &:before {
    margin-left: -10px;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: $white;
    border-width: 10px;
  }
}

.book-popover {
  .book-title {
    font-size: $font-size-h4;
    font-weight: 600;
  }

  .book-byline {
    display: block;
    margin-bottom: 15px;
  }

  .book-synopsis {
    color: #6c6c6c;
    font-size: $font-size-small;
  }

  .book-store-links {
    li {
      margin-bottom: 12px;
    }
  }
}

#bible-actions,
#bible-popover {
  position: absolute;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: visibility .2s, opacity .2s;
  @include shadow;

  &.show-popover {
    visibility: visible;
    opacity: 1;
  }

  &.hide-popover {
    visibility: hidden;
    opacity: 0;
  }
}

#bible-actions {
  display: flex;

  button {
    float: left;
    color: $gray-lighter;
    background-color: transparent;
    margin: 0 5px 0 0;
    padding: 5px;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      color: $white;
    }

    &:disabled:hover {
      color: $gray-lighter;
    }
  }

  .bible-actions-buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: $gray-darker;

    .bible-actions-close {
      align-self: flex-end;
      margin: 0 0 0 5px;
    }

    &#bible-actions-primary-buttons {
      .bible-actions-close {
        margin: 0;
      }
    }

    // Tool tip arrow
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
    }

    &:after {
      margin-left: -7px;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $gray-darker;
      border-width: 7px;
    }

    &:before {
      margin-left: -8px;
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: $gray-darker;
      border-width: 8px;
    }
  }

  #bible-actions-highlight-colours {
    .bible-highlight-colour {
      width: 16px;
      height: 16px;
      margin: 10px 6px;
      background-color: transparent;
      border-radius: 3px;
    }

    .bible-highlight-colour-blue {
      background-color: rgba(0, 185, 255, 255) !important;
    }

    .bible-highlight-colour-orange {
      background-color: rgba(246, 140, 30 ,255) !important;
    }

    .bible-highlight-colour-green {
      background-color: rgba(0, 201, 60 ,1) !important;
    }

    .bible-highlight-colour-pink {
      background-color: rgba(255, 116, 185, 1) !important;
    }

    .bible-highlight-colour-yellow {
      background-color: rgba(236, 249, 0, 1) !important;
    }

    .bible-highlight-remove {
      background-color: rgba(255, 255, 255, 1) !important;
    }
  }

  #bible-actions-note-form {
    textarea {
      width: 300px;
      height: 150px !important;
      min-height: 150px !important;
      max-height: 150px !important;
    }
  }
}

#bible-popover {
  display: flex;
  min-width: 150px;
  max-width: 300px;
  flex-wrap: wrap;

  button {
    float: left;
    color: $gray-lighter;
    background-color: transparent;
    margin: 0 5px 0 0;
    padding: 5px;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      color: $white;
    }

    &:disabled:hover {
      color: $gray-lighter;
    }
  }

  .popover-controls {
    flex: 1 0 100%;
    position: relative;
    display: flex;
    padding: 10px 15px;
    background-color: $gray-darker;

    .popover-name {
      width: 100%;
      color: $white;
      font-size: $font-size-small;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
    }

    .popover-close {
      align-self: flex-end;
      margin: 0 0 0 5px;
    }

    // Tool tip arrow
    &:after,
    &:before {
      bottom: 100%;
      left: 50%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid transparent;
    }

    &:after {
      margin-left: -7px;
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: $gray-darker;
      border-width: 7px;
    }

    &:before {
      margin-left: -8px;
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: $gray-darker;
      border-width: 8px;
    }
  }

  .popover-content {
    flex: 1 0 100%;
    padding: 15px;
    background-color: $white;
  }
}
