.side-nav {
  width: 100% !important;

  ul,
  dl {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li,
    dt,
    dd {
      &.related-post-item {
        padding: 15px 8px;
        border-bottom: 1px solid #d0d0d0;

        a {
          color: #6c6c6c;
          font-size: $font-size-small;

          &:hover {
            color: $gray-dark;
          }
        }
      }

      &.related-posts-group-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 15px 20px;
        cursor: pointer;
        @extend .all-caps;
        text-align: left;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        transition: all .2s;

        &:after {
          // .icon-arrow-down
          content: "\e604";
          padding: 0 0 0 10px;
          color: $gray-lighter;
          font-family: 'simple-line-icons';
          font-size: 14px;
          transition: all .2s;
        }

        &:hover,
        &:focus {
          color: $black;

          &:after {
            color: $black;
          }
        }

        &.open {
          transition: all .2s;
          color: $black;

          &:after {
            // .icon-arrow-up
            content: "\e607";
            color: $black;
            transition: all .2s;
          }
        }
      }
    }

    dd {
      padding: 15px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    dd > ul > li {
      padding: 5px 0 !important;
      border-bottom: 0 none !important;
    }
  }
}

.newlife-tiles {
  @extend .col-xs-12;
  margin-top: 30px;
  margin-bottom: 30px;

  .nav-secondary {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0;

      li {
        flex: 0 1 48%;
        margin-bottom: 30px;
        padding: 0;
        // overflow: hidden;
        @include shadow();

        @include mappy-bp(md) {
          flex: 0 1 22.5%;
        }
      }

      li > a {
        @include aspect-ratio(1, 1);
        display: block;
        overflow:hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &:hover,
        &:focus {
          // TODO: Rework markup so that only image scales, not text
          // transform: scale(1.1);

          .description {
            color: $black;
          }
        }
      }

      .menu-tile-label {
        position: absolute;
        top: 0;
        padding: 8px 12px;
        color: #fff;
        letter-spacing: 1px;
        background-color: #252525;
      }

      .description {
        display: inline-block;
        width: 100%;
        padding: 8px 12px;
        color: #7f7f7f;
        font-size: $font-size-base;
        text-transform: none;
        background-color: #fff;
      }

      .menu-square-one .menu-tile-label, .menu-depart .menu-tile-label {
        background-color: #0B4D99;
      }

      .menu-tell-me-more .menu-tile-label, .menu-dites-men-plus .menu-tile-label {
        background-color: #F78D1E;
      }

      .menu-bible-answers .menu-tile-label, .menu-reponses-de-la-bible .menu-tile-label  {
        background-color: #CA2027;
      }

      .menu-ready-to-change .menu-tile-label, .menu-pret-a-changer .menu-tile-label {
        background-color: #008744;
      }
    }
  }
}

.dig-deeper {
  margin-top: 30px;
  margin-bottom: 30px;

  .dig-deeper-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
    position: relative;
    height: 200px;
    background-color: #eee;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    h5 {
      font-family: $font-family-headings;
      font-size: $font-size-h3;
    }
  }

  .reading-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .book-item {
      flex: 0 1 50%;
      padding: 15px;

      @include mappy-bp(sm) {
        flex: 0 1 25%;
      }

      @include mappy-bp(md) {
        flex: 0 1 20%;
      }
    }
  }

  .book-show-popover {
    display: inline-block;
    color: #6c6c6c;
    text-transform: uppercase;

    &:hover,
    &:focus,
    &:active:focus {
      color: $brand-primary;
      text-decoration: none;
    }
  }

  .book-author {
    display: block;
    color: #6c6c6c;
    font-size: $font-size-small;
  }
}
