body {
  background-color: $white;
}

// Links and buttons
a:link,
a:visited,
a:hover,
a:active,
a:focus,
a span,
a.btn,
button {
  transition: .3s !important;

  &:before,
  &:after {
    transition: .3s !important;
  }
}

a:hover, a:focus {
  text-decoration: none;
  outline: 0;
}

// Icons
.icon {
  font-family: 'simple-line-icons';
  font-size: 18px;
  line-height: $line-height-base;
}

// Horizontal rule
hr {
  border-color: rgba(0, 0, 0, .1);
  margin: 30px 0 ;
}

// Labels
.label {
  padding-left: 0;
  padding-right: 0;
  font-size: $font-size-base;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: normal !important;
}

// All-caps heading
// Extend to list items, component headings and citations
.all-caps {
  color: $gray;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

// Bible formatting
span.sc,
span.sc-red {
  font-size: 80%;
}

// Roots Share Buttons custom styles
.entry-share {
  margin-top: 60px;

  .entry-share-btns {
    @extend .list-inline;

    .entry-share-btn {
      .entry-share-btn-icon {
        display: table;
        float: left;
        height: 36px;
        width: 36px;
        background-color: $gray-light;
        border-radius: 50%;
        text-align: left !important;

        svg {
          padding: 5px;
          height: 30px;
          width: 30px;
          fill: $white;
          position: relative;
          left: 3px;
          top: 3px;
        }
      }

      &.entry-share-btn-facebook {
        .entry-share-btn-icon {
          background-color: #3b5998;
        }

        &:hover {
          .entry-share-btn-icon {
            background-color: darken(#3b5998, 5%);
          }
        }
      }

      &.entry-share-btn-google-plus {
        .entry-share-btn-icon {
          background-color: #dd4b39;
        }

        &:hover {
          .entry-share-btn-icon {
            background-color: darken(#dd4b39, 5%);
          }
        }
      }

      &.entry-share-btn-linkedin {
        .entry-share-btn-icon {
          background-color: #007bb6;
        }

        &:hover {
          .entry-share-btn-icon {
            background-color: darken(#007bb6, 5%);
          }
        }
      }

      &.entry-share-btn-pinterest {
        .entry-share-btn-icon {
          background-color: #cb2027;
        }

        &:hover {
          .entry-share-btn-icon {
            background-color: darken(#cb2027, 5%);
          }
        }
      }

      &.entry-share-btn-twitter {
        .entry-share-btn-icon {
          background-color: #00aced;
        }

        &:hover {
          .entry-share-btn-icon {
            background-color: darken(#00aced, 5%);
          }
        }
      }

      &.entry-share-btn-email {
        .entry-share-btn-icon {
          background-color: $gray-light;
        }

        &:hover {
          .entry-share-btn-icon {
            background-color: darken($gray-light, 5%);
          }
        }
      }

      .count {
        position: relative;
        min-width: 30px;
        float: right;
        margin-left: 10px;
        padding: 5px 8px;
        color: $gray-light;
        text-align: center;
        border: 1px solid $gray-lighter;
        border-radius: 4px;

        &:after,
        &:before {
          right: 100%;
          top: 50%;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border: solid transparent;
        }

        &:after {
          margin-top: -5px;
          border-color: rgba(255, 255, 255, 0);
          border-right-color: $white;
          border-width: 5px;
        }

        &:before {
          margin-top: -6px;
          border-color: rgba(0, 0, 0, 0);
          border-right-color: $gray-lighter;
          border-width: 6px;
        }
      }
    }
  }
}

// Loading indicator
// http://projects.lukehaas.me/css-loaders/
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader:before,
.loader:after {
  position: absolute;
  content: '';
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: $white;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.2em 5.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader {
  color: $gray-light;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: $white;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  -webkit-transform-origin: 0px 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Fade out element
// .hide-animate-before {
//   height: 100%;
//   transform: translateY(0%);
//   opacity: 1;
//   visibility: visible;
//   transition: .3s all;
// }
//
// .hide-animate-after {
//   height: 0px;
//   transform: translateY(-100%);
//   opacity: 0;
//   visibility: hidden;
//   transition: .3s all;
// }
