@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/simple-line-icons/scss/simple-line-icons.scss";
// endbower

@import "../../bower_components/mappy-breakpoints/_mappy-breakpoints.scss";

@import "common/breakpoints";

@import "common/global";
@import "common/mixins";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/popovers";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";


// NewLife user theme: Seasonal
// NOTE: Edit SCSS within themes directory, as it gets duplicated into main styles directory for some reason.

// Header banners
.banner {
  background-image: url('../images/themes/seasonal/default.jpg') !important;
}

.bible .banner {
  background-image: url('../images/themes/seasonal/bible.jpg') !important;
}

.square-one .banner {
  background-image: url('../images/themes/seasonal/square-one.jpg') !important;
}

.tell-me-more .banner {
    background-image: url('../images/themes/seasonal/tell-me-more.jpg') !important;
}

.ready-to-change .banner,
.becoming-a-follower-of-jesus .banner,
.my-commitment .banner,
.join-the-family .banner {
  background-image: url('../images/themes/seasonal/ready-to-change.jpg') !important;
}

.bible-answers .banner,
.bible-terms .banner {
  background-image: url('../images/themes/seasonal/bible-answers.jpg') !important;
}

.find-a-church .banner {
  background-image: url('../images/themes/seasonal/find-a-church.jpg') !important;
}

.request-a-prayer .banner {
  background-image: url('../images/themes/seasonal/request-a-prayer.jpg') !important;
}

// NewLife aside
.newlife-tiles {
  .menu-square-one a, .menu-depart a {
    background-image: url('../images/themes/seasonal/square-one-thumb.jpg') !important;
  }

  .menu-tell-me-more a, .menu-dites-men-plus a {
    background-image: url('../images/themes/seasonal/tell-me-more-thumb.jpg') !important;
  }

  .menu-ready-to-change a, .menu-pret-a-changer a {
    background-image: url('../images/themes/seasonal/ready-to-change-thumb.jpg') !important;
  }

  .menu-bible-answers a, .menu-reponses-de-la-bible a {
    background-image: url('../images/themes/seasonal/bible-answers-thumb.jpg') !important;
  }
}

// Dig Deeper aside
.square-one .dig-deeper-header {
  background-image: url('../images/themes/seasonal/square-one.jpg') !important;

  h5 {
    color: #fff;
  }
}

.tell-me-more .dig-deeper-header {
  background-image: url('../images/themes/seasonal/tell-me-more.jpg') !important;

  h5 {
    color: #fff;
  }
}

.ready-to-change .dig-deeper-header,
.join-the-family .dig-deeper-header {
  background-image: url('../images/themes/seasonal/ready-to-change.jpg') !important;

  h5 {
    color: #fff;
  }
}

// Sign up prompt aside
.sign-up-prompt {
  background-image: url('../images/themes/seasonal/default-thumb.jpg') !important;
}

.request-a-prayer .sign-up-prompt {
  background-image: url('../images/themes/seasonal/request-a-prayer-thumb.jpg') !important;
}

// Prayer Request aside
#prayer-request-banner {
  background-image: url('../images/themes/seasonal/request-a-prayer.jpg') !important;
}


// // Header banners
// .banner {
//   background-image: url('../images/themes/flora/default.jpg');
// }
// 
// .bible .banner {
//   background-image: url('../images/themes/flora/bible.jpg');
// }
// 
// .square-one .banner {
//   background-image: url('../images/themes/flora/square-one.jpg');
// }
// 
// .tell-me-more .banner {
//     background-image: url('../images/themes/flora/tell-me-more.jpg');
// }
// 
// .ready-to-change .banner,
// .becoming-a-follower-of-jesus .banner,
// .my-commitment .banner,
// .join-the-family .banner {
//   background-image: url('../images/themes/flora/ready-to-change.jpg');
// }
// 
// .bible-answers .banner,
// .bible-terms .banner {
//   background-image: url('../images/themes/flora/bible-answers.jpg');
// }
// 
// .find-a-church .banner {
//   background-image: url('../images/themes/flora/find-a-church.jpg');
// }
// 
// .request-a-prayer .banner {
//   background-image: url('../images/themes/flora/request-a-prayer.jpg');
// }
// 
// // NewLife aside
// .newlife-tiles {
//   .menu-square-one a, .menu-depart a {
//     background-image: url('../images/themes/flora/square-one-thumb.jpg');
//   }
// 
//   .menu-tell-me-more a, .menu-dites-men-plus a {
//     background-image: url('../images/themes/flora/tell-me-more-thumb.jpg');
//   }
// 
//   .menu-ready-to-change a, .menu-pret-a-changer a {
//     background-image: url('../images/themes/flora/ready-to-change-thumb.jpg');
//   }
// 
//   .menu-bible-answers a, .menu-reponses-de-la-bible a {
//     background-image: url('../images/themes/flora/bible-answers-thumb.jpg');
//   }
// }
// 
// // Dig Deeper aside
// .square-one .dig-deeper-header {
//   background-image: url('../images/themes/flora/square-one.jpg');
// 
//   h5 {
//     color: $white;
//   }
// }
// 
// .tell-me-more .dig-deeper-header {
//   background-image: url('../images/themes/flora/tell-me-more.jpg');
// 
//   h5 {
//     color: $white;
//   }
// }
// 
// .ready-to-change .dig-deeper-header,
// .join-the-family .dig-deeper-header {
//   background-image: url('../images/themes/flora/ready-to-change.jpg');
// 
//   h5 {
//     color: $white;
//   }
// }
//
// // Sign up prompt aside
// .sign-up-prompt {
//   background-image: url('../images/themes/flora/default-thumb.jpg');
// }
//
// .request-a-prayer .sign-up-prompt {
//   background-image: url('../images/themes/flora/request-a-prayer-thumb.jpg');
// }
//
// // Prayer Request aside
// #prayer-request-banner {
//   background-image: url('../images/themes/flora/request-a-prayer.jpg');
// }

.lead {
  font-weight: 300 !important;
}

.modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100vh !important;
  width: 100vw !important;
}

.modal-dialog {
  flex: 0 1 auto !important;
  text-align: center !important;
}

.modal-content {
  padding: 30px;
  border: 0 none !important;
  border-radius: 0px !important;

  .bootstrap-dialog-title {
    margin-right: -5px;
    font-size: $font-size-h3;
    font-family: $font-family-headings;
  }

  .bootstrap-dialog-close-button button {
    margin-top: -15px;
    margin-right: 5px;
    font-size: $font-size-h3 !important;
  }

  .bootstrap-dialog-footer-buttons {
    text-align: center !important;
  }
}

.modal-header,
.modal-content,
.modal-footer {
  border: 0 none;
}

.panel-default {
  background: $white;
  border: 0 none;
  @include shadow;

  .panel-heading {
    padding: 30px 30px 0 30px;
    background: $white;
    border: 0 none;
  }

  .panel-body {
    padding: 15px 30px;
    background: $white;
    border: 0 none;
  }
}

.alert-info {
  padding: 30px 30px;
  background-color: $white;
  border: 0 none;
  @include shadow;

  .alert-heading {
    margin-top: 0;
    margin-bottom: 10px;

    h3 {
      @extend .all-caps;
      margin-top: 0;

      .icon {
        color: #ca2027;
      }
    }
  }

  .alert-body {
    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.alert-dismissible {
  position: relative !important;
}

.feature-description {
  @extend .lead;
}

.sharing-links {
  @extend .list-inline;
  padding: 15px 0;

  li {
    padding: 0 5px;
  }

  li > a:link,
  li > a:visited {
    text-decoration: none;

    &:after {
      display: inline-block;
      height: 30px;
      width: 30px;
      color: rgba(0,0,0,.4);
      font-family: simple-line-icons;
      font-size: 16px;
      font-weight: 300;
      line-height: 30px;
      cursor: pointer;
      background-color: hsla(0,0%,100%,.6);
      border-radius: 50%;
      transition: all .2s;
    }
  }

  li > a:hover:after {
    color: rgba(0, 0, 0, .8);
    background-color: rgba(255, 255, 255, .9);
    transition: all .2s;
  }

  .share-facebook:after {
    content: "\e00b";
  }

  .share-twitter:after {
    content: "\e009";
  }

  .share-pinterest:after {
    content: "\e60b";
  }

  .share-instagram:after {
    content: "\e609";
  }
}

// Definition links
// Pointed events are enabled by JS on load and prevent default click event
a[href^='/def/'] {
  pointer-events: none;
}
