.content-info {
  padding: 30px 0;
  font-size: $font-size-small;
  background-color: #e5e5e5;

  .menu {
    padding: 0;
    list-style-type: none;

    li {
      margin-bottom: 5px;

      a:link,
      a:visited {
        color: $gray;
      }

      a:active,
      a:hover,
      a:focus {
        color: $gray-dark;
      }
    }
  }

  div.lang-selector {
      ul {
        @extend .list-inline;

        li {
          a {
            color: $gray-light;
          }

          &.current-lang a {
            color: $gray-dark;
            text-decoration: underline;
          }
        }
      }
    }
}
